<template>
  <div class="box">
    <div class="header">
      <div class="header-search">
        <van-nav-bar
          title="搜索"
          left-text=""
          left-arrow
          @click-left="onClickLeft"
        >
        </van-nav-bar>
      </div>
      <div class="choose">
        <van-search
          v-model="appSearchName"
          placeholder="请输入关键词"
          @search="onSearch"
          show-action
          shape="round"
        >
          <template #action>
            <div @click="onSearch(appSearchName)">确定</div>
          </template>
        </van-search>
      </div>
    </div>
    <div class="content">
      <div class="content-search">
        <!-- 搜索列表 -->
        <van-loading color="#1989fa" v-if="loadingV" />
        <van-empty
          description="未找到搜索结果"
          v-if="formConfig.data.length == 0 && !falg && !loadingV"
        />
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
          :immediate-check="false"
          v-if="formConfig.data.length !== 0"
        >
          <list :formConfig="formConfig" ref="far" />
        </van-list>
        <!-- 历史记录 -->
        <div class="record-box" v-if="falg && taa.length != 0">
          <div class="record">
            <span class="recordleft">搜索历史</span>
            <div class="recordicon" @click="dele">
              <van-icon name="delete-o" size="13" /><span class="icontext"
                >清除</span
              >
            </div>
          </div>
          <div
            class="record-data"
            v-for="(item, index) in taa"
            :key="index"
            @click="onSearch(item.search)"
          >
            <span class="datarecord"
              ><van-icon name="underway-o" style="margin-right: 4px" />
              {{ item.search }}</span
            >
            <van-icon name="arrow" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Toast } from "vant";
export default {
  data() {
    return {
      appSearchTypeval: "",
      typeval: "",
      loadingV: false,
      pageNum: 1,
      pageSize: 10,
      loading: false,
      finished: true,
      isLoading: false,
      active: "f",
      falg: true,
      appSearchName: "",
      taa: [],
      formConfig: {
        data: [],
        columns: [
          {
            prop: "manufactureName",
            label: "制造商",
          },
          {
            prop: "erpcontractno",
            label: "采购合同号",
          },
          {
            prop: "diliverydate",
            label: "交货日期",
          },
          {
            prop: "qty",
            label: "采购数量",
          },
        ],
        total: 0,
        url: "manufacturschemeDetails",
      },
    };
  },
  created() {
    this.appsearchloglist();
  },
  methods: {
    async approvalList(o) {
      const params = {
        pageIndex: this.pageNum,
        pageSize: this.pageSize,
        appSearchName: this.appSearchName,
        sort: "desc",
        sortType: 1,
        statusType: 1,
        appSearchType: 240,
      };
      let data = await this.$api.ec.procmanuproject(params);
      if (data) {
        this.loadingV = false;
        if (o == 1) {
          if (data.length == 0) {
            this.formConfig.data = data;
          } else {
            this.formConfig.data = data.data;

            this.formConfig.data.map((item) => {
              item.createUser = item.userName;
              item.supplierName = item.projectTopic;
              item.qty = `${item.qty}${item.measuint}`;
            });
          }
        } else {
          this.formConfig.data = [...this.formConfig.data, ...data.data];
          this.formConfig.data.map((item) => {
            item.createUser = item.userName;
            item.supplierName = item.projectTopic;
            item.qty = `${item.qty}${item.measuint}`;
          });
        }
        if (data.total != 10) {
          this.loading = true;
          this.finished = true;
        } else {
          this.pageNum++;
          this.loading = false;
          this.finished = false;
        }
      }
    },
    dele() {
      this.delete();
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    onRefresh() {
      this.loading = true;
    },
    onSearch(o) {
      this.appSearchName = o;
      if (!this.appSearchName) {
        Toast.fail("请输入查询内容");
      } else {
        this.pageNum = 1;
        this.loadingV = true;
        this.falg = false;
        this.appSearchName = o;
        this.approvalList(1);
      }
    },
    onLoad() {
      this.approvalList(2);
    },
    listDetails() {
      this.$router.push({ name: "ecprocurement" });
    },
    async appsearchloglist() {
      let params = {
        pageIndex: 1,
        pageSize: 10,
        type: 240,
      };
      let data = await this.$api.ec.appsearchloglist(params);
      if (data.data) {
        this.taa = data.data;
      }
    },
    async delete() {
      let params = {
        type: 30,
      };
      let data = await this.$api.ec.delete(params);
      console.log(data);
      if (data) {
        this.taa = [];
      }
    },
  },
};
</script>
<style lang="less">
.content-search {
  min-height: 100%;
  .record-box {
    padding: 0.3rem;
    .record {
      width: 100%;
      margin-top: 50px;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .recordleft {
        color: #8f8f8f;
      }
      .recordicon {
        display: flex;
        align-items: center;

        .icontext {
          color: #2d8aff;
        }
      }
    }
    .record-data {
      font-size: 12px;
      color: #333;
      border: 1px solid rgb(240, 236, 236);
      line-height: 30px;
      padding: 0 0.3rem;
      display: flex;
      align-items: center;
      margin-top: 5px;
      justify-content: space-between;
      .datarecord {
        display: flex;
        align-items: center;
        margin-left: 2px;
      }
    }
  }
}
</style>
